import axios from 'axios';
import nProgress from "nprogress";
//引入进度条的样式
import "nprogress/nprogress.css" //想要更改进度条颜色也需要修改这个文件中的css样式

// 引入baseURL
import baseURL from './baseUrl'


// var baseURL = 'http://192.168.31.99/api/mallAdmin' // 开发
// var baseURL = 'https://mall-admin.mihuan.shop/api/backend' // 正式




const https = axios.create({
    baseURL: baseURL,
    timeout: 5000
})
// 请求拦截器
https.interceptors.request.use((config) => {
    nProgress.start();
    // config.headers.Authorization = localStorage.token;
    return config;
})

// 响应拦截器
https.interceptors.response.use((res) => {
    nProgress.done();
    return res.data;
}, (err) => {
    // if (err ? .response ? .status == 401) {
    //     localStorage.removeItem('token');
    //     MessageBox.alert('登录已过期,请重新登录', '警告', {
    //         type: 'warning',
    //         confirmButtonText: '确定',
    //         callback: action => {
    //             //跳转
    //             router.replace('/')
    //         }
    //     });
    // }
    return Promise.reject(err.message);
});

//暴露
export default https;