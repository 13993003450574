<template>
    <div class="Footer">
        <!-- 底部分割线 -->
        <div class="line"></div>
        <div class="w-pc">
            <div class="footer-content ">
                <!-- 顶部 -->
                <div class="footer-top ">
                    <!-- 上方左侧导航 -->
                    <div class="footer-top-left">
                        <div class="coloum" v-for="item in list" :key="item.id">
                            <!-- <div class="title hand" @click="link_Click(item.page, item.href,item.id)">{{ item.name }}</div> -->
                            <div class="title hand" @click="toPage(item.page)">{{ item.name }}</div>
                            <ul>
                                <li class="hand" @click="link_Click(son.page, son.href,son.id)" v-for=" son in item.son"
                                    :key="son.id">{{ son.name }}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="foot-40"></div>
                    <!-- 上方右侧公司信息 -->
                    <div class="footer-top-right">
                        <div class="box">
                            <div class="img-box">
                                <img src="https://file.heipa365.com/official/map.png" />
                            </div>
                            <div>成都市高新区府城大道西段天府新谷8号楼</div>
                        </div>
                        <div class="box">
                            <div class="img-box">
                                <img src="@/assets/img/qqicon@2x.png" />
                            </div>

                            <div>2560526407@qq.com</div>
                        </div>
                        <div class="box mb53" >
                            <div class="img-box">
                                <img src="https://file.heipa365.com/official/photo.png" />
                            </div>
                            <div>028-87335180</div>
                        </div>
                        <!-- 下载 -->
                        <div class="download-shell ">
                            <div class="img-box download">
                                <div style="position: relative;" @mouseenter="isShow1 = true" @mouseleave="isShow1 = false">
                                    <!-- <img @click="isShow1 = !isShow1; isShow2 = false; isShow3 = false" src="../assets/img/xiazainew.png" /> -->
                                    <!-- 跳转到下载页面 -->
                                    <img  src="https://file.heipa365.com/official/xiazainew.png" @click="link_Click('/qsddDownload')"/>
                                    <div class="download-box " v-if="isShow1">
                                        <!-- 左侧展示二维码 -->
                                        <div class="download-left">
                                            <div class="left-img-box">
                                                <img src="https://file.heipa365.com/official/anzuo.png" alt="Android下载">
                                                <img src="https://file.heipa365.com/official//IOStu.png" alt="ios下载" style="display: none;">
                                            </div>
                                            <div class="left-text">扫码下载秘欢商城APP</div>
                                        </div>
                                        <!-- 右侧内容 -->
                                        <div class="download-right">
                                            <div class="text-box w128" >
                                                <img class="icon-box"
                                                    src="https://file.heipa365.com/official/dinganzuo.png" />
                                                <div>Android下载</div>
                                            </div>
                                            <div class="text-box w82" >
                                                <img class="icon-box"
                                                    src="https://file.heipa365.com/official/dingpingguo.png" />
                                                <div>ios下载</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style="position: relative;" class="to-link" >
                                    <!-- 跳转链接 https://weibo.com/u/7499230009 -->
                                    <a href="https://weibo.com/u/7499230009" @mouseenter="isShow2 = true" @mouseleave="isShow2 = false">
                                        <!-- <img @click="isShow2 = !isShow; isShow1 = false; isShow3 = false" src="../assets/img/weibonew.png" /> -->
                                        <img src="../assets/img/weibonew.png" />
                                    </a>
                                    <div class="code" v-if="isShow2">
                                        <img src="../assets/img/weiboewm.png" alt="">
                                    </div>
                                </div>
                                <div style="position: relative;" class="to-link">
                                    <!-- 跳转链接 https://www.xiaohongshu.com/user/profile/5f4f5c600000000001007ba1 -->
                                    <a href="https://www.xiaohongshu.com/user/profile/5f4f5c600000000001007ba1" @mouseenter="isShow3 = true" @mouseleave="isShow3 = false">
                                        <!-- <img @click="isShow3 = !isShow3; isShow2 = false; isShow1 = false" src="../assets/img/xiaoredshunew.png" /> -->
                                        <img src="../assets/img/xiaoredshunew.png" />
                                    </a>
                                    <div class="code" v-if="isShow3">
                                        <img src="../assets/img/redewm.png" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 下载弹窗 -->

                    </div>
                </div>
                <!-- 底部版权  -->
                <div class="footer-bottom">
                    <div>Copyright © 2022 四川趣事多多网络科技有限公司 版权所有 <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备18026698号</a></div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    // {
    //                     id: 23,
    //                     name: "合作伙伴",
    //                     href: "#workWith",// 锚点
    //                     page: "/qsddAbout",//主页面路由
    //                 },
    name: 'qsdd-footer',
    data() {
        return {
            list: [
                {
                    id: 1,
                    name: "新闻中心",
                    href: "",// 锚点  默认son内第一额
                    page: "/qsddNews/NewsCompany",
                    son: [
                        {
                            id: 11,
                            name: "公司动态",
                            href: "",// 锚点
                            page: "/qsddNews/NewsCompany",//主页面路由
                        }, {
                            id: 12,
                            name: "行业资讯",
                            href: "",// 锚点
                            page: "/qsddNews/NewsInformation",//主页面路由
                        }]
                },
                {
                    id: 2,
                    name: "关于我们",
                    href: "",// 锚点  默认son内第一额
                    page: "/qsddAbout/aboutCompany",
                    son: [{
                        id: 21,
                        name: "公司简介",
                        href: "aboutCompany",// 锚点
                        page: "/qsddAbout/aboutCompany",//主页面路由
                    }, {
                        id: 22,
                        name: "企业历程",
                        href: "companyWays",// 锚点
                        page: "/qsddAbout/aboutCompany",//主页面路由
                    }, {
                        id: 24,
                        name: "联系我们",
                        href: "contactUs",// 锚点
                        page: "/qsddAbout/aboutCompany",//主页面路由
                    }]
                }, {
                    id: 3,
                    name: "产品介绍",
                    href: "productShop",// 锚点  默认son内第一额
                    page: "/qsddProduct/productShop",
                    son: [
                        {
                            id: 31,
                            name: "秘欢商城",
                            href: "productShop",// 锚点
                            page: "/qsddProduct/productShop",//主页面路由
                        }
                    ]
                },
                {
                    id: 4,
                    name: "加入我们",
                    href: "partnerWithme",// 锚点  默认son内第一额
                    page: "/qsddPartner/partnerWithme",
                }
            ],
            isShow1: false,
            isShow2: false,
            isShow3: false
        }
    },
    methods: {
        link_Click(page, href) {
            if (this.$route.path != page) {
                if(href){
                    this.$router.push({
                        path: page,
                        query: {
                            href: href
                        }
                    })
                    this.$emit("change", href)
                }else{
                    this.$router.push({
                        path: page,
                        query: {
                            href: href
                        }
                    })
                    window.scrollTo(0,0)
                }
                
            }else{
                this.$emit("change", href)
            }
            if (this.$route.path != page) {
                // 滚动到指定锚点
                return
            }
        },
        // 页面跳转
        toPage(page){
            this.$router.push({ path: page})
            window.scrollTo(0,0)
        },
        // 锚点跳转
        toAnchor(href){
            console.log(href);
        }
    },
    created() {

    }
}
</script>

<style lang="scss" scoped>
.hand {
    cursor: pointer;
}

.line {
    position: absolute;
    // width: 100%;
    width: 1920px;
    bottom: 197px;
    left: 0;
    // border-width: 1px;
    // border-style: solid;
    // border-color: rgba(255, 255, 255, 0.15);
    // transform: scale(0.5)
}
.line::after{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            box-sizing: border-box;
            width: 200%;
            height: 200%;
            border-width: 1px;
            border-style: solid;
            border-color: rgba(255, 255, 255, 0.15);
            transform-origin: 0 0;
            transform: scale(0.5);
            /* z-index: 0; */
        }
.Footer {
    font-size: 16px;
    background-color: #28282a;
    position: relative;
}

.footer-content {
    display: flex;
    flex-direction: column;
    height: 500px;
    width: 100%;
    color: #d7d7d7;
    // padding: 0 48px;
    // background-color: #28282a;
    box-sizing: border-box;

    .footer-top {
        box-sizing: border-box;
        display: flex;
        height: 340px;
        text-align: center;
        padding-top: 70px;
        .foot-40{
            width: 400px;
        }

        .footer-top-left {
            display: flex;
            flex-wrap: nowrap;
            width: 680px;
            min-width: 360px;
            height: 100%;
            justify-content: space-between;

            .coloum {
                width: 80px;

                .title {
                    font-size: 20px;
                    color: white;
                    margin-bottom: 49px;
                }

                ul li {
                    margin-bottom: 28px;
                }

            }
        }

        .footer-top-right {
            flex-shrink: 0;
            width: 400px;
            height: 100%;
            .mb53{
                margin-bottom: 53px;
            }
            .box {
                position: relative;
                display: flex;
                height: 25px;
                line-height: 25px;
                margin-bottom: 33px;

                .img-box {
                    vertical-align: middle;

                    img {
                        width: 25px;
                        height: 25px;
                        padding-right: 12px;
                    }
                }


            }

            .download-shell {

                position: relative;
                display: flex;
                height: 25px;
                line-height: 25px;
                margin-bottom: 33px;
                position: relative;
                display: flex;
                height: 45px;
                line-height: 25px;
                margin-bottom: 33px;

                .download {
                    display: flex;
                    width: 263px;
                    height: 45px;
                }

                .img-box {
                    img {
                        width: 45px;
                        height: 45px;
                        margin-right: 64px;
                        cursor: pointer;
                    }
                }
            }

            .download-box {
                position: absolute;
                top: calc(100% + 20px);
                left: 22.5px;
                transform: translateX(-50%);
                display: flex;
                padding-left: 18px;
                padding-right: 16px;
                padding-top: 20px;
                width: 296px;
                height: 151px;
                background-color: #939394;
                border-radius: 10px;

                .download-left {
                    margin-bottom: 23px;

                    .left-img-box {
                        margin-left: 27px;
                        margin-bottom: 15px;
                        width: 80px;
                        height: 80px;
                        vertical-align: middle;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .left-text {
                        color: #1e1e1e;

                    }

                }

                .download-right {
                    padding-top: 5px;
                    .w128{
                        width: 128px;
                    }
                    .w82{
                        width: 82px;
                    }

                    .text-box {
                        display: flex;
                        align-items: center;
                        height: 28px;
                        line-height: 28px;
                        vertical-align: middle;
                        padding-left: 12px;
                        background-color: #fff;
                        border-radius: 28px;
                        color: #1e1e1e;
                        margin-bottom: 12px;


                        .icon-box {
                            height: 13px;
                            width: 13px;
                            margin-right: 6px;
                        }

                    }
                }
            }

            .to-link{

                .code {
                    position: absolute;
                    top: calc(100% + 20px);
                    left: 22.5px;
                    transform: translateX(-50%);
                    width: 160px;
                    height: 160px;
                    background-color: #939394;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }

            }

            
        }

    }

    .footer-bottom {
        height: 50px;
        // line-height: 130px;
        text-align: center;
        margin-top: 40px;
        // border-top: 1px solid #aaa;
        // color: #d7d7d7;
        a{
            color: #008cff;
        }
    }
}
</style>