<template>
  <div class="home">
    <!-- 头部 -->
    <keep-alive>
      <Header @change="href_Change"></Header>
    </keep-alive>
    <!-- 内容 -->
    <div class="content">
      <div class="top">
        <div class="banner" >
          <img src="https://file.heipa365.com/official/homeb%402x.png" alt="" />
        </div>
        
        <!-- <img class="mihuan-text" src="https://file.heipa365.com/official/秘欢文字.png" alt="" />
        <img class="qingqu-text" src="https://file.heipa365.com/official/情趣文案.png" alt="" /> -->
        <!-- <div class="download-box"> -->
        <img class="db-img" @click="showIos = !showIos" src="https://file.heipa365.com/official/ios-download.png"
          alt="" />
        <div class="ewm-box" v-show="showIos">
          <img class="" src="https://file.heipa365.com/official/IOStu.png" alt="" />
          <div class="text">扫码下载秘欢商城APP</div>
        </div>

        <img class="db-img" style="left: 52.6%" @click="showAndroid = !showAndroid"
          src="https://file.heipa365.com/official/android-download.png" alt="" />
        <div class="ewm-box" v-show="showAndroid" style="left: 54.38%">
          <img src="@/assets/img/anzuo.png" alt="" />
          <div class="text">扫码下载秘欢商城APP</div>
        </div>
      </div>

      <!-- 中间内容 -->
      <div class="center">
        <div class="w-pc">
          <div class="title-shell">
            <div class="title">
              <div class="title-c">新闻中心</div>
              <div class="title-e">News</div>
            </div>
          </div>
          <!-- 新闻中心 -->
          <div class="news">
            <div class="news-title">
              <div class="left ellipsis" @click="goToDetail(newsCompanyData[0].id)">
                {{ newsCompanyData[0]?.title }}
              </div>
            </div>
            <div class="news-title">
              <div class="left ellipsis" @click="goToDetail(newsCompanyData[1].id)">
                {{ newsCompanyData[1]?.title }}
              </div>
              <div class="shortline"></div>
              <div class="right ellipsis" @click="goToDetail(newsCompanyData[2].id)">
                {{ newsCompanyData[2]?.title }}
              </div>
            </div>
            <!-- 公司动态 -->
            <div class="news-company">
              <div class="tit">
                <div class="red"></div>
                <div class="text">公司动态</div>
              </div>
              <div class="content">
                <div class="box" v-for="item in newsCompanyData" :key="item.id" @click="goToDetail(item.id)">
                  <div class="left">
                    <div class="text">
                      {{ item.title }}
                    </div>
                    <div class="time">{{ item.publishDate.slice(0, 11) }}</div>
                  </div>
                  <img class="right" :src="item.imgUrl" />
                </div>

              </div>
            </div>
            <!-- 行业资讯 -->
            <div class="news-company">
              <div class="tit">
                <div class="red"></div>
                <div class="text">行业资讯</div>
              </div>
              <div class="content">
                <div class="box" v-for="item in newsInfoData" :key="item.id" @click="goToDetail(item.id)">
                  <div class="left">
                    <div class="text">
                      {{ item.title }}
                    </div>
                    <div class="time">{{ item.publishDate.slice(0, 11) }}</div>
                  </div>
                  <img class="right" :src="item.imgUrl" />
                </div>

              </div>
            </div>
            <div style="display: flex; justify-content: center">
              <!-- 跳转到新闻中心公司动态页面 -->
              <img @click="goToNews" class="check-more" src="https://file.heipa365.com/official/home/Slice 12.png" alt="" />
            </div>
          </div>
          <!-- 关于我们 -->
          <div class="title-shell">
            <div class="title">
              <div class="title-c">关于我们</div>
              <div class="title-e">About</div>
            </div>
          </div>
          <div class="company">
            <div class="c-top">
              <div style="display: flex;">
                <div class="box1">
                <!-- <img src="@/assets/img/about/Slice 13@2x.png"> -->
                <div class="text1">
                  四川趣事多多网络科技有限公司，自2018年在成都这座富有文化底蕴和现代活力的城市成立以来，一直秉承创新与责任并重的理念，深耕于成人情趣用品行业。
                  <div class="arrow">→</div>
                </div>
              </div>
              <div class="box2">
                <div class="text">
                  作为一家充满活力的互联网企业。我们认识到，随着社会的不断进步，成年人对于生活质量的追求已经从基本的满足上升到了对美好生活的向往。在这样的背景下，我们立下了清晰的使命：通过提供高品质、富有创意的情趣用品，为成年人群创造一个更加安全、健康、和谐、美好的生活空间。创新是我们的核心竞争力。
                </div>
              </div>
              </div>
              <div style="display: flex;">
                 <div class="box3"></div>
              <div class="box4">
                <div class="text-box">
                  <div class="up">User-用户体验</div>
                  <div class="center">
                    我们不断探索和引入新材料、新工艺，力求在产品设计、功能、材质上达到行业领先水平。同时，我们也注重用户体验，通过线上平台的便捷购物和线下店的沉浸式体验，线上线下结合的商业模式，满足用户对隐私和个性化服务的需求。
                  </div>
                  <!-- 跳转到关于我们公司简介页面 -->
                  <div class="line">
                    <img @click="goToAbout(21)" src="@/assets/img/home/Slice learn@2x.png" alt="">
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>

          <!-- 2024.06.03 后端这边让隐藏此部分 -->
          <!-- 产品介绍  产品展示-->
          <!-- <div class="title-shell">
            <div class="title">
              <div class="title-c">产品介绍</div>
              <div class="title-e">Products</div>
            </div>
          </div> -->
          <!-- <div class="introduce">
            <div class="bottom">
              <img class="left-img" src="../assets/img/product/product-left1.png" alt="" />
              <div class="right">
                <div class="right-title">
                  合作品牌198家，上架商品5000+，服务用户386万人次
                </div>
                <div class="right-text">
                  秘欢商城致力于打造高性价比多选择性的成人两性情趣用品商城，用科技升华情趣，拒绝一成不变，享受极致快乐。商城合作品牌198家，上架商品5000+，服务用户386万人次，品类多样海量选择真实评价，24小时在线客服，为用户提供丰富的购物选择、舒适的购物体验。
                </div>
                <div class="s-line"></div>
                
                <img @click="goToProduct(31)" class="red" src="@/assets/img/home/Slice red@2x.png" alt="">
              </div>
            </div>
          </div> -->
          <!-- <div class="product-show">
            <div class="top">
              <div class="dot"></div>
              <div class="tip">产品展示</div>
            </div>
            <div class="bottom">
              <div class="img m90" >
                <img src="../assets/img/product/slice6.png" alt="" />
                <div class="img-text">
                  <div class="f-title" >专注</div>
                  <div class="f-main" style=" color: rgba(255, 255, 255, 0.8)">
                    成人情趣用品
                  </div>
                </div>
              </div>
              <div class="img m290" >
                <img src="../assets/img/product/slice7.png" alt="" />
                <div class="img-text">
                  <div class="f-title">提供</div>
                  <div class="f-main" style=" color: rgba(255, 255, 255, 0.8)">
                    美好健康生活
                  </div>
                </div>
              </div>
              <div class="img m60">
                <img src="../assets/img/product/slice8.png" alt="" />
                <div class="img-text">
                  <div class="f-title">打造</div>
                  <div  class="f-main" style=" color: rgba(255, 255, 255, 0.8)">
                    健康情趣生活
                  </div>
                </div>
              </div>
            </div>
          </div> -->


          <!-- 加入我们 -->
          <div class="title-4">
            <div class="text-1">联系方式</div>
            <div class="text-2">
              我们期待与您携手共创美好未来。如果您对成为我们的投资合作伙伴感兴趣，请通过以下方式与我们联系：
            </div>
          </div>
          <div class="contact">
            <div class="company-info">
              <div class="name">四川趣事多多网络科技有限公司</div>
              <div class="info">
                <img class="info-icon" src="@/assets/img/home/Slice 10@2x.png" alt="" />
                <div>冯健</div>
              </div>
              <div class="info">
                <img class="info-icon" src="https://file.heipa365.com/official/home/Slice 10@2x(1).png" alt="" />
                <div>028-87335180</div>
              </div>
              <div class="info">
                <img class="info-icon" src="@/assets/img/home/Slice 10@2x(2).png" alt="" />
                <div>成都市高新区府城大道西段天府新谷8号楼</div>
              </div>
              <div class="tips">加入我们，共同开启情趣用品行业新篇章。</div>
            </div>
            <div class="form">
              <div class="rubric">请填写您的联系方式</div>
              <el-form ref="form" :model="form" label-width="120px" label-position="top">
                <el-form-item label="姓名">
                  <el-input v-model="form.name" placeholder="请输入您的姓名"></el-input>
                </el-form-item>
                <el-form-item label="联系电话">
                  <el-input v-model="form.phone" placeholder="请输入您的联系电话"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button class="my-btn" @click="onSubmit">提交信息</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <Footer @change="href_Change"></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import https from "@/utils/requests.js";
export default {
  name: "qsddHome",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      showIos: false, //是否显示ios二维码
      showAndroid: false, //是否显示安卓二维码
      form: {
        name: "",
        phone: "",
      },
      //新闻公司动态数据
      newsCompanyData:[],
      // newsCompanyData: [{
      //   id: 1,
      //   title: "【趣事多】2022年趣事多APP产品发布会",
      //   content: "2022年趣事多APP产品发布会",
      //   publishDate: "2022-03-15",
      //   imgUrl: require("../assets/img/product/slice8.png"),
      // }, {
      //   id: 2,
      //   title: "【趣事多】2022年趣事多APP产品发布会",
      //   content: "2022年趣事多APP产品发布会",
      //   publishDate: "2022-03-15",
      //   imgUrl: require("../assets/img/product/slice8.png"),
      // }, {
      //   id: 3,
      //   title: "【趣事多】2022年趣事多APP产品发布会",
      //   content: "2022年趣事多APP产品发布会",
      //   publishDate: "2022-03-15",
      //   imgUrl: require("../assets/img/product/slice8.png"),
      // }],
      
      // //新闻新闻资讯数据
      newsInfoData: [
        // {
        //   id: 1,
        //   title: "【趣事多】2022年趣事多APP产品发布会",
        //   content: "2022年趣事多APP产品发布会",
        //   publishDate: "2022-03-15",
        //   imgUrl: require("../assets/img/product/slice8.png"),
        // }, {
        //   id: 2,
        //   title: "【趣事多】2022年趣事多APP产品发布会",
        //   content: "2022年趣事多APP产品发布会",
        //   publishDate: "2022-03-15",
        //   imgUrl: require("../assets/img/product/slice8.png"),
        // }, {
        //   id: 3,
        //   title: "【趣事多】2022年趣事多APP产品发布会",
        //   content: "2022年趣事多APP产品发布会",
        //   publishDate: "2022-03-15",
        //   imgUrl: require("../assets/img/product/slice8.png"),
        // }
      ],
      id: 0,
      
    };
  },
  created() {
    this.getCompanyNews();
    this.getInfoNews()
  },
  mounted(){
        const ref = this.$refs[this.$route.query.href]
        if(ref){
            ref.scrollIntoView();
        }
    },
  methods: {
    // 验证手机号格式
    validatePhone(phoneNumber) {
      const phoneRegex = /^\d{11}$/;
      return phoneRegex.test(phoneNumber);
    },
    // 表单提交
    onSubmit() {
      console.log("submit!");
      // 检查表单是否填写完整  
      if (!this.form.name || !this.form.phone) {
        this.$message({
          message: "请提交完整信息！",
          type: "error",
        });
        return; // 阻止提交  
      }
      // 检查电话号码格式  
      if (!this.validatePhone(this.form.phone)) {
        this.$message({
          message: "请输入正确的电话号码！",
          type: "error",
        });
        return; // 阻止提交  
      }
    },
    //新增合伙人
    async postPartent() {
      // 接收数据时不需要再解构
      const data = await https({
        // 去掉服务器的路径
        url: "/officialWebsite/addPartner",
        method: "POST",
        data: {
          ...this.form,
        },
      });
      if (data.code == 0) {
        this.$message({
          message: "提交信息成功！",
          type: "success",
        });
        this.form = {};
      }
    },
    // 跳转到产品页
    goToNews() {
      this.$router.push({ path: "/qsddNews/NewsCompany" })
      window.scrollTo(0, 0);
    },
    // 跳转到关于我们公司简介页面
    goToAbout() {
      this.$router.push({ path: "/qsddAbout/aboutCompany#id" })
      window.scrollTo({
        top: 100,
        // behavior: 'smooth'
      })

    },
    // 跳转到产品页
    goToProduct() {
      // #id
      this.$router.push({ path: "/qsddProduct/productShop" })
      window.scrollTo(0, 0);
    },
    //跳转详情页
    goToDetail(id) {
      this.$router.push({ path: "/qsddNews/NewsDetail", query: { id } })
      window.scrollTo(0, 0);
    },
    //新闻数据  行业
    async getInfoNews() {
      const data = await https({
        url: "/officialWebsite/newsList",
        method: "GET",
        params: {
          status: 1,
          type: 2,
          page: 1,
          limit: 4,
        },
      });
      if (data.code == 0) {
        this.newsInfoData = data.data.list;
      }
    },
    //新闻数据动态
    async getCompanyNews() {
      const data = await https({
        url: "/officialWebsite/newsList",
        method: "GET",
        params: {
          status: 1,
          type: 1,
          page: 1,
          limit: 4,
        },
      })
      if (data.code == 0) {
        this.newsCompanyData = data.data.list;
      }
    },
    link_Click(page, href) {
      if (this.$route.path != page) {
        this.$router.push({
          path: page,
          query: {
            href: href,
          },
        });
        return;
      }
      if (this.$route.path != page) {
        // 滚动到指定锚点
        return;
      }
    },
    href_Change(href) {
      console.log(href);
      const ref = this.$refs[href];
      if (ref) {
        ref.scrollIntoView();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ellipsis {
  overflow: hidden;
  /* 确保超出容器的文本会被裁剪 */
  white-space: nowrap;
  /* 确保文本在一行内显示 */
  text-overflow: ellipsis;
  /* 使用省略号表示文本溢出 */
}

.home {

  // background: url("@/assets/img/banner.png") no-repeat center center;
  .content {
        background-color: #000;
    .top {
      position: relative;

      .banner {
        width: 100%;
        // height: calc(100% / 1.76);
        height: 1080px;
        vertical-align: -webkit-baseline-middle;
        vertical-align: middle;
        background-color: #000;
        img{
          width: 100%;
          height: 100%;
        }
      }

      .mihuan-text {
        position: absolute;
        top: 43.8%;
        left: 10.5%;
        width: 27.2%;
        height: 17.78%;
      }

      .qingqu-text {
        position: absolute;
        top: 62.69%;
        right: 7.4%;
        width: 14.58%;
        height: 9.44%;
      }

      .download-box {
        display: flex;
        position: absolute;
        top: 80.56%;
        left: 34.58%;
        // width: 592px;
        height: 70px;
      }

      .db-img {
        position: absolute;
        top: 80.56%;
        left: 34.58%;
        // width: 246px;
        width: 12.81%;
        // vertical-align: middle;
        // width: 200px;
        // height: 100%;
        // height: 0.98%;
        margin-right: 100px;
      }

      .ewm-box {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        position: absolute;
        // left: 36.46%;
        // top: 74.38%;
        // top: 720px;
        // left: 696px;
        top: 63.56%;
        left:36.25%;
        // width: 172px;
        // height: 152px;
        width: 8.96%;
        height: 16.12%;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 4px 4px 4px 4px;

        img {
          // width: 80px;
          // height: 80px;
          width: 48%;
          margin: 0 auto;
        }

        .text {
          width: 100%;
          text-align: center;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #1a1a1a;
          margin-top: 7%;
        }
      }
    }

    .center {
      position: relative;
      width: 100%;
      height: calc(100% / 1.76);
      background: url("https://file.heipa365.com/official/bgImg@2x.png");
      background-repeat: repeat;
      background-size: contain;

      .title-shell {
        width: 100%;
        display: flex;
        justify-content: center;

        .title {
          position: relative;
          // height: 85px;
          padding-top: 104px;
          padding-bottom: 50px;
          margin: 0 auto;

          .title-e {
            //   position: absolute;
            //   left: 42.34%;
            // top: 68.52%;
            //   top: 33px;
            //   width: 294px;
            //   height: 85px;
            font-family: Arial Black, Arial Black;
            font-weight: 400;
            font-size: 60px;
            color: #2b2a2a;
            line-height: 70px;
          }

          .title-c {
            color: #fff;
            position: absolute;
            left: 6px;
            top: 94px;
            width: 160px;
            height: 56px;
            font-family: PingFang SC, PingFang SC;
            // font-weight: 600;
            font-size: 40px;
            line-height: 47px;
          }
        }
      }

      .news {
        position: relative;
        width: 1480px;
        cursor: pointer;

        // border: 1px solid red;
        .news-title {
          display: flex;
          align-items: center;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 26px;
          color: #ffffff;
          margin-top：48px

          

          .left {
            max-width: 562px;
            height: 52px;
            line-height: 52px;
          }
          .shortline{
                  width: 1px !important;
                  height: 26px !important;
                  background: #fff !important;
                  margin: 0 16px !important;
          }

          .right {
            position: relative;
            max-width: 562px;
            height: 52px;
            line-height: 52px;
          }
        }

        .news-company {
          .tit {
            display: flex;
            align-items: center;
            padding-top: 70px;
            padding-bottom: 46px;

            .red {
              width: 16px;
              height: 16px;
              background: #f83801;
              border-radius: 50%;
              margin-right: 30px;
            }

            .text {
              width: 128px;
              height: 45px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 600;
              font-size: 32px;
              color: #ffffff;
              line-height: 38px;
            }
          }

          .content {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 1480px;

            .box {
              // box-sizing: border-box;
              display: flex;
              width: 700px;
              height: 146px;
              border-bottom: 1px solid #484747;
              padding-top: 26px;
              padding-bottom: 14px;

              // margin-right: 80px;
              .left {
                // display: flex;
                width: 496px;
                height: 132px;

                .text {
                  width: 496px;
                  height: 80px;
                  font-family: PingFang SC, PingFang SC;
                  font-weight: 500;
                  font-size: 20px;
                  color: #ffffff;
                  line-height: 40px;
                  margin-right: 24px;
                }

                .time {
                  width: 105px;
                  height: 36px;
                  font-family: PingFang SC, PingFang SC;
                  font-weight: 500;
                  font-size: 18px;
                  margin-top: 16px;
                  color: rgba(255, 255, 255, 0.8);
                  line-height: 36px;
                }
              }

              .right {
                width: 180px;
                height: 120px;
              }
            }
          }
        }

        .check-more {
          margin-top: 80px;
          width: 145px;
          height: 63px;
        }
      }

      .company {
        .c-top {
          width: 1480px;
          height: 840px;
          // display: flex;
          // flex-wrap: wrap;

          .box1 {
            position: relative;
            width: 740px;
            height: 420px;
            background: url("@/assets/img/about/Slice 13@2x1.png") no-repeat;
            background-size: cover;
            font-family: PingFang SC, PingFang SC;

            .text1 {
              position: absolute;
              top: 151px;
              left: 81px;
              height: 106px;
              width: 578px;
              // height: 64px;\
              font-size: 16px;
              font-weight: 400;
              
              color: #ffffff;
              line-height: 32px;
              text-align: center;

              .arrow {
                position: absolute;
                top: 65px;
                left: 270px;
                width: 33px;
                height: 16px;
                line-height: 16px;
                text-align: center;
                color: #f03a0e;
              }
            }
          }

          .box2 {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 740px;
            height: 420px;
            background: #f03a0e;
            line-height: 420px;
            text-align: center;

            .text {
              width: 544px;
              height: 160px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              
              font-size: 16px;
              color: #ffffff;
              line-height: 32px;
              text-align: left;
            }
          }

          .box3 {
            width: 740px;
            height: 420px;
            background: url("@/assets/img/about/Slice 13@2x2.png");
            background-size: cover;
          }

          .box4 {
            position: relative;
            width: 740px;
            height: 420px;
            background: #f6f6f6;

            .text-box {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              position: absolute;
              top: 65px;
              left: 98px;
              width: 544px;
              height: 220px;
              z-index: 2;

              .up {
                width: 150px;
                height: 44px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                font-size: 22px;
                color: #1a1a1a;
                line-height: 44px;
                text-align: left;
              }

              .center {
                width: 544px;
                height: 128px;
                font-weight: 400;
                font-size: 16px;
                color: #4d4d4d;
                line-height: 32px;
                background: #f6f6f6 !important;
              }

              .line {
                width: 145px;
                height: 63px;
                margin-top: 32px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }

      .introduce {
        width: 100%;
        height: 35.6%;

        .bottom {
          display: flex;

          width: 100%;
          height: 78.24%;

          .left-img {
            width: 532px;
            height: 532px;
            padding-right: 6.25%;
          }

          .right {
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
            font-family: PingFang SC, PingFang SC;
            width: 100%;
            margin-top: 2.24%;

            .right-title {
              width: 641px;
              height: 36px;
              font-weight: 500;
              font-size: 26px;
              color: #ffffff;
              line-height: 30px;
              text-align: left;
              // margin-top: 5%;
              margin-bottom: 2%;
            }

            .right-text {
              width: 828px;
              height: 132px;
              padding-bottom: 32px;
              margin-top: 58px;
              font-weight: 400;
              font-size: 20px;
              color: rgba(255, 255, 255, 0.7);
              line-height: 44px;
              text-align: left;
            }

            .s-line {
              width: 148px;
              height: 4px;
              background: #f03a0e;
              margin-bottom: 100px;

            }

            .red {
              width: 145px;
              height: 63px;

            }
          }
        }
      }

      .product-show {
        width: 1480px;

        .top {
          display: flex;
          align-items: center;
          position: relative;
          width: 1480px;
          padding-top: 60px;
          height: 76px;
          border-bottom: 1px solid #484747;

          .dot {
            height: 14px;
            width: 14px;
            line-height: 14px;
            border-radius: 50%;
            background-color: red;
          }

          .tip {
            flex: 1;
            height: 76px;
            line-height: 76px;
            color: #fff;
            font-size: 20px;
            padding-left: 30px;
          }
        }

        .bottom {
          display: flex;
          justify-content: space-around;
          width: 100%;
          .m90{
            margin-top: 90px
          }
          .m290{
            margin-top: 290px
          }
          .m60{
            margin-top: 60px
          }
          .img {
            width: 340px;
            height: 803px;
            

            img {
              width: 340px;
              height: 710px;
              // background: #fff;
            }

            .img-text {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              padding-top: 24px;
              color: #fff;
              .f-title{
                font-size: 32px;
              }
              .f-main{
                font-size: 16px;
              }

            }
          }
        }
      }

      .title-4 {
        padding-top: 100px;

        .text-1 {
          color: #fff;
          height: 56px;
          line-height: 56px;
          font-size: 40px;
          font-weight: 600;
        }

        .text-2 {
          height: 40px;
          line-height: 40px;
          font-weight: 500;
          font-size: 20px;
          color: rgba(255, 255, 255, 0.7);
          margin-top: 24px;
        }
      }

      .contact {
        display: flex;
        justify-content: space-between;
        padding-top: 60px;
        padding-bottom: 100px;

        .company-info {
          padding-top: 30px;
          color: #fff;

          .name {
            font-size: 28px;
            padding-bottom: 32px;
            font-weight: bold;
          }

          .info {
            display: flex;
            align-items: center;
            height: 40px;
            font-size: 20px;
            padding-bottom: 24px;
            font-weight: 500;

            img {
              width: 40px;
              height: 40px;
              // background-color: aqua;
              margin-right: 10px;
            }
          }

          .tips {
            height: 44px;
            line-height: 44px;
            font-weight: 500;
            font-size: 31px;
            color: rgba(255, 255, 255, 0.7);
            padding-top: 100px;
          }
        }

        .form {
          width: 652px;
          height: 595px;
          background: #f5f5f7;
          border-radius: 8px;
          padding: 100px;
          box-sizing: border-box;

          .rubric {
            font-weight: bold;
            font-size: 28px;
            color: #1a1a1a;
            line-height: 33px;
            text-align: left;
            font-style: normal;
            text-transform: none;
            margin-bottom: 32px;
          }

          ::v-deep .el-input__inner {
            height: 60px !important;
            width: 443px !important;
            margin-bottom: 24px;
            font-weight: 400;
            font-size: 20px;
          }

          ::v-deep .el-form-item__label {
            font-weight: 700;
            font-size: 24px;
            color: #1a1a1a;
            line-height: 28px;
            text-align: left;
            font-style: normal;
            text-transform: none;
          }

          .my-btn {
            width: 452px;
            height: 60px;
            background: #f83801;
            text-align: center;
            border-radius: 4px 4px 4px 4px;
            font-weight: 500;
            font-size: 20px;
            color: #ffffff;
            line-height: 23px;
            font-style: normal;
            text-transform: none;
          }
        }
      }
    }
  }
}
</style>