<template>
  <div class="bigBox">
    <!-- 下面整体盒子 -->
    <div class="downBox">
      <!-- header -->
      <qsddHeader ></qsddHeader>

      <!-- 内容盒子 -->
      <div class="contentBox">
        <div class="titleBox">
          <img class="titleImg" src="@/assets/mobile/imgs/mihuanTextWhite.png" alt="">
          <div class="textBox">
              <div class="txt">真实</div>
              <img src="@/assets/mobile/imgs/textLine.png" alt="">
              <div class="txt">方便</div>
              <img src="@/assets/mobile/imgs/textLine.png" alt="">
              <div class="txt">快捷</div>
              <img src="@/assets/mobile/imgs/textLine.png" alt="">
              <div class="txt">安全</div>
              <img src="@/assets/mobile/imgs/textLine.png" alt="">
              <div class="txt">私密</div>
          </div>
          <div class="downloadBox">
            <!-- 苹果下载跳转  现在没有 就不展示 -->
            <!-- <img class="box" src="@/assets/mobile/imgs/appWhiteText.png" alt="" @click="toDownload(0)" > -->
            <!-- 安卓下载跳转 -->
            <img class="box" style="margin: 16px 0;" src="@/assets/mobile/imgs/androidWhiteText.png" alt="" @click="toDownload(1)">
            <!-- 小程序下载跳转  打开新页面 展示小程序二维码 -->
            <img class="box" src="@/assets/mobile/imgs/applet.png" alt=""  @click="toApplet">
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <!-- <qsddFooter></qsddFooter> -->
  </div>
</template>

<script>
// import qsddFooter from "@/components/qsddFooter.vue";
import qsddHeader from "@/components/mobile/qsddHeader.vue";
import https from "@/utils/requests.js";

export default {
    name: "mQsddHome",
    components: {
      qsddHeader,
      // qsddFooter,
    },
    data(){
        return{
          title:'秘欢-移动首页',
          isIos: false,
          isAndroid: false,
          schemeUrl:'',
          downloadUrl:'',
          ACCESS_TOKEN:'',
          url:'',

        }
    },
    created() {
      // var u = navigator.userAgent;
      // console.log('u',u);
      
      // //var app = navigator.appVersion;
      // var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
      // var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      // if (isAndroid) {
      //   this.isAndroid = true;
      //   this.schemeUrl= '你的androidSchemeUrl';
      //   this.downloadUrl = 'https://file.heipa365.com/shop/time=1722931047517_8383.apk';
      // }
      // if (isIOS) {
      //   this.isIos = true;
      //   this.schemeUrl= '你的iosSchemeUrl';
      //   this.downloadUrl = '你的iosapp下载地址';
      // }
  },
  mounted() {
    // if (!this.isBlackApp()) {
    //     this.openTuer();
    // } else {
    //    //显示手动打开外置浏览器提示
    // }
    
    this.getAndroidUrl();
  },
  methods: {
    // 获取安卓下载地址
    async getAndroidUrl() {
      let res = await https.get("/set/lastedOfficialVersion")
        // console.log(res.data);
        this.url = JSON.parse(res.data).url;
        // console.log(this.url); 
    },
    // 跳转到下载页面
    toDownload(index){
      switch (index) {
        // 苹果
        case 0:
          window.open()
        break;
        // 安卓
        case 1:
          // window.open("https://file.heipa365.com/shop/time=1722931047517_8383.apk")
          window.open(this.url)
        break;
        // 小程序
        case 2:
          window.open("")
        break;
      }
    },
    // 跳转到小程序二维码下载页面
    toApplet(){
      this.$router.push('/mobile/qsddApplet');
    },


















    /*** 判断浏览器是否为头部APP*/
    isBlackApp() {
      var u = navigator.userAgent.toLowerCase();
      return /micromessenger/i.test(u) || u.indexOf("weibo") > -1 || u.indexOf("qq") > -1 || u.indexOf('mqqbrowser') > -1;
    },
    // 点击打开app 唤醒app得方法
    openTuer() {
      if (JSON.parse(sessionStorage.getItem("vuex"))) {
        this.$options.methods.jumpApp(this.SchemeUrl+'拼接参数');
        this.$options.methods.noApp();
      } else {
        this.$options.methods.jumpApp(this.SchemeUrl);
        this.$options.methods.noApp();
      }
    },
    // 跳转打开app
    jumpApp(t) {
      try {
        var e = navigator.userAgent.toLowerCase(),
          n = e.match(/cpu iphone os (.*?) like mac os/);
        if (
          ((n = null !== n ? n[1].replace(/_/g, ".") : 0), parseInt(n) >= 9)
        ) {
          window.location.href = t;
        } else {
          var r = document.createElement("iframe");
          (r.src = t), (r.style.display = "none"), document.body.appendChild(r);
        }
      } catch (e) {
        window.location.href = t;
      }
    },
    // 无响应或者没安装跳转下载
    noApp() {
      var t = Date.now(),
        r = this.downloadUrl;
      this.timer = setTimeout(function() {
        return Date.now() - t > 2200
          ? (clearTimeout(this.timer), !1)
          : !document.webkitHidden &&
              !document.hidden &&
              void (location.href = r);
      }, 2000);
    },
     
  },

}
</script>

<style lang="scss" scoped>

.bigBox{
  min-height: 100vh;
  // height: 100%;

  background: url('https://file.heipa365.com/mall/activity/mihuanBGImage.png');
  background-size: cover;
  box-sizing: border-box;
  padding-bottom: 20px;
  // overflow-y: hidden;
}



.downBox{
  width: 100%;
  .contentBox{
    margin-top: 100px;
    margin-left: 67px;
    margin-right: 67px;
    box-sizing: border-box;
    width: 241px;
    // border: 1px solid pink;
    border-radius: 0px 0px 0px 0px;
    .titleBox{
      box-sizing: border-box;
      width: 241px;
      .titleImg{
        width: 138px;
        height: 81px;
        margin: 0 51px 20px ;
      }
      .textBox{
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        width: 241px;
        height: 18px;
        line-height: 18px;
        font-size: 13px;
        color: rgba(255,255,255,0.8); 
        .txt{
          width: 29px;
          font-size: 13px;
        }
        img{
          width: 10px;
          height: 10px;
          margin-top: 4px;
        }
      }
      .downloadBox{
        box-sizing: border-box;
        margin: 100px 48px 0;
        img{
          width: 146px;
          height: 48px;
          border-radius: 8px 8px 8px 8px;
          border: 1px solid rgba(255,255,255,0.5);
        }
      }
    }
    
  }
}


</style>