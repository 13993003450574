// 默认值

// 路由配置文件
import Vue from "vue";
import VueRouter from "vue-router";
// import {pcRoutes} from "./pc/index.js";
// import {mobileRoutes} from "./mobile";

import Home from "@/pages/index/pc/Home.vue";
import qsddHome from "@/pages/index/mobile/qsddHome.vue";

import { isMobile } from '../utils/index'


Vue.use(VueRouter);

// // 首页用重定向的方式来进行适配的方案：
const redirectPath =isMobile ? '/mobile/qsddHome' : '/Home';
console.log(redirectPath);




// 报错显示路由重复  
const originalPush = VueRouter.prototype.push
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// let routes = isMobile() ? mobileRoutes : pcRoutes;
// const router = new VueRouter({
//   mode: 'hash', // 使用 hash 模式
//   routes,
// })

const router =new VueRouter({
  mode: 'hash',
  routes: [
    // 重定向
    {
      path: '/',
      redirect: redirectPath,
      components: {
          default: Home,
          pc: Home,
          m: qsddHome
      }
    },{
      path: '/Home',
      name: 'Home',
      component: () => import('@/pages/index/pc/Home.vue')
    },
    {
        path: '/qsddAbout',
        name: 'qsddAbout',
        component: () => import('@/pages/qsddAbout'),
        children: [{
                path: 'aboutCompany',
                component: () => import('@/pages/qsddAbout/aboutCompany'),
            },
            {
                path: 'companyWays',
                component: () => import('@/pages/qsddAbout/companyWays'),
            },
            {
                path: 'workWith',
                component: () => import('@/pages/qsddAbout/workWith'),
            },
            {
                path: 'comoanyWays',
                component: () => import('@/pages/qsddAbout/contactUs'),
            },
            {
                // 重定向 公司介绍默认是aboutCompany页面
                path: '/qsddAbout',
                redirect: '/qsddAbout/aboutCompany'
            }
        ]
    },
    {
        path: '/qsddNews/NewsCompany',
        component: () => import('@/pages/qsddNews/NewsCompany'),
        // beforeEnter(to,from,next){
        //     window.scrollTo(0,0);
        //     next();
        // }
    },
    {
        path: '/qsddNews/NewsDetail',
        component: () => import('@/pages/qsddNews/NewsDetail'),
        // beforeEnter(to,from,next){
        //     window.scrollTo(0,0);
        //     next();
        // }
    },
    {
        path: '/qsddNews/NewsInformation',
        component: () => import('@/pages/qsddNews/NewsInformation'),
        // beforeEnter(to,from,next){
        //     window.scrollTo(0,0);
        //     next();
        // }
    },
    {
        path: '/qsddProduct',
        name: 'qsddProduct',
        component: () => import('@/pages/qsddProduct'),
        // beforeEnter(to,from,next){
        //     window.scrollTo(0,0);
        //     next();
        // },
        children: [{
                path: 'productShop',
                component: () => import('@/pages/qsddProduct/productShop'),
            },
            {
                // 重定向 
                path: '/qsddProduct',
                redirect: '/qsddProduct/productShop'
            }
        ]
    },
    {
        path: '/qsddPartner',
        name: 'qsddPartner',
        component: () => import('@/pages/qsddPartner'),
        children: [{
                path: 'partnerWithme',
                component: () => import('@/pages/qsddPartner/partnerWithme'),
            },
            {
                // 重定向 
                path: '/qsddPartner',
                redirect: '/qsddPartner/partnerWithme'
            }
        ]

    },
    {
        path: '/qsddDownload',
        name: 'qsddDownload',
        component: () => import('@/pages/qsddDownload')
    },
    // 咪语官网  2024.07.04新增
    {
        path: '/miyupc/miyupc',
        component: () => import('@/pages/miyupc/miyupc'),
    },
    // 移动端
    // 首页
    {
      path: '/mobile/qsddHome',
      name: 'm_qsddHome',
      component: () => import('@/pages/index/mobile/qsddHome.vue')
    },
    // 新闻中心
    {
        path: '/mobile/qsddNews/qsddNews',
        name: 'qsddNews',
        component: () => import('@/pages/mobile/qsddNews/qsddNews.vue'),
        // children: [{
        //         path: 'newsDetail',
        //         component: () => import('@/pages/mobile/qsddNews/newsDetail'),
        //     },
        // ]
    },
    // 新闻详情
    {
        path: '/mobile/qsddNews/NewsDetail',
        component: () => import('@/pages/mobile/qsddNews/newsDetail'),
        // beforeEnter(to,from,next){
        //     window.scrollTo(0,0);
        //     next();
        // }
    },
    // 下载页面
    {
        path: '/mobile/qsddDownload',
        name: 'qsddDownload',
        component: () => import('@/pages/mobile/qsddDownload')
    },
    // 关于我们
    {
        path: '/mobile/qsddAbout',
        name: 'qsddAbout',
        component: () => import('@/pages/mobile/qsddAbout')
    },
    // 产品介绍
    {
        path: '/mobile/qsddProduct',
        name: 'qsddProduct',
        component: () => import('@/pages/mobile/qsddProduct')
    },
    // 寻找合伙人
    {
        path: '/mobile/qsddPartner',
        name: 'qsddPartner',
        component: () => import('@/pages/mobile/qsddPartner')
    },
    // 小程序二维码下载页面
    {
        path: '/mobile/qsddApplet',
        name: 'qsddApplet',
        component: () => import('@/pages/mobile/qsddApplet')
    },
]
})

export default router;