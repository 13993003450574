<template>
  <div class="header" style="width: 100%">
    <div class="w-pc">
      <div class="header-cnt">
        <div class="left-header">
          <div class="logo">
            <img src="https://file.heipa365.com/official/logo.png" alt="秘欢" />
          </div>
          <div class="text">四川趣事多多网络科技有限公司</div>
        </div>
        <div class="w216"></div>
        <div class="center-header">
          <ul>
            <li
              class="el-dropdown-link"
              :class="$route.path == '/Home' ? 'path-active' : ''"
              @click="link_Click('/Home')"
            >
              首页
            </li>
            <el-dropdown
              :show-timeout="50"
              placement="bottom"
              v-for="item in tabList"
              :key="item.id"
            >
              <li
                class="el-dropdown-link"
                :class="
                  strSplit($route.path) == strSplit(item.page)
                    ? 'path-active'
                    : ''
                "
                @click="link_Click(item.page, item.href, item.id)"
              >
                {{ item.name }}
              </li>
              <template v-if="item.son.length > 0">
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(son, index) in item.son"
                    :key="son.id"
                  >
                    <div
                      class="link"
                      :class="
                        $route.query.href == son.href ||
                        (!$route.query.href &&
                          index == 0 &&
                          strSplit($route.path) == strSplit(item.page))
                          ? 'bg-red'
                          : ''
                      "
                      @click="link_Click(son.page, son.href, son.id)"
                    >
                      {{ son.name }}
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </ul>
        </div>

        <div class="w17"></div>
        <div class="right-header" @click="toDownload">
          <img src="@/assets/img/Slice 42@2x.png" alt="下载" />
          <!-- <div class="icon">
            <img src="@/assets/img/Slice 42@2x.png" alt="下载" />
          </div>
          <div class="downloadApp">下载App</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "qsdd-header",
  data() {
    return {
      tabList: [
        {
          id: 1,
          name: "新闻中心",
          href: "NewsCompany", // 锚点  默认son内第一个
          page: "/qsddNews/NewsCompany",
          son: [
            {
              id: 11,
              name: "公司动态",
              href: "NewsCompany", // 锚点
              page: "/qsddNews/NewsCompany", //主页面路由
            },
            {
              id: 12,
              name: "行业资讯",
              href: "NewsInformation", // 锚点
              page: "/qsddNews/NewsInformation", //主页面路由
            },
          ],
        },
        {
          id: 2,
          name: "关于我们",
          href: "", // 锚点  默认son内第一额
          page: "/qsddAbout/aboutCompany",
          son: [
            {
              id: 21,
              name: "公司简介",
              href: "aboutCompany", // 锚点
              page: "/qsddAbout", //主页面路由
            },
            {
              id: 22,
              name: "企业历程",
              href: "companyWays", // 锚点
              page: "/qsddAbout", //主页面路由
            },
            {
              id: 24,
              name: "联系我们",
              href: "contactUs", // 锚点
              page: "/qsddAbout", //主页面路由
            },
          ],
        },
        {
          id: 3,
          name: "产品介绍",
          href: "productShop", // 锚点  默认son内第一额
          page: "/qsddProduct/productShop",
          son: [
            {
              id: 31,
              name: "秘欢商城",
              href: "productShop", // 锚点
              page: "/qsddProduct", //主页面路由
            },
          ],
        },
        {
          id: 4,
          name: "寻找合伙人",
          href: "partnerWithme", // 锚点  默认son内第一额
          page: "/qsddPartner/partnerWithme",
          son: [
            {
              id: 41,
              name: "与我合作",
              href: "partnerWithme", // 锚点
              page: "/qsddPartner", //主页面路由
            },
          ],
        },
      ],
    };
  },

  created() {},
  methods: {
    strSplit(str) {
      return str.split("/")[1];
    },
    link_Click(page, href) {
      if (this.$route.path != page) {
        // console.log("跳转",this.$route.path,page,id);
        // console.log("跳转", page,href);
        this.$router.push({
          path: page,
          query: {
            href: href,
          },
        });
        this.$emit("change", href);
      }
      if (this.$route.path != page) {
        // 滚动到指定锚点
        // this.anchorJump(id);
        // 页面内容滚动到对应锚点位置
        return;
      }
      // if(id){
      //     this.anchorJump(id);
      //     return;
      // }
    },
    // anchorJump(which) {
    //     let id = "#" + which; // 给id加上井号
    //     // 给对应dom滚动到对应锚点，使之出现在视图中
    //     document.querySelector(id).scrollIntoView({
    //         behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
    //         block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
    //         inline: "center", // 定义水平滚动方向的对齐
    //     });
    // },
    // toTop() {
    //     if (this.$refs.toTopRef.scrollTop == 0) {
    //         return;
    //     }
    //     /**
    //      * 使用循环定时器，定时更新其高度，直至高度为0，才去清除定时器
    //      * 同时再更正一下高度位置为0
    //      * */
    //     let timer = setInterval(() => {
    //         this.$refs.toTopRef.scrollTop = this.$refs.toTopRef.scrollTop - 30;
    //         console.log("实时高度scrollTop", this.$refs.toTopRef.scrollTop);
    //         if (this.$refs.toTopRef.scrollTop <= 0) {
    //         clearInterval(timer);
    //         this.$refs.toTopRef.scrollTop = 0;
    //         }
    //     }, 16); // 30和16这两个参数感觉比较平滑一些
    // },
    // 跳转到下载页面
    toDownload() {
      this.$router.push({
        path: "/qsddDownload",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .popper__arrow {
  display: none;
}

.el-dropdown-menu {
  padding: 0;
  border: 0;
}

.el-dropdown-link {
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
}

.bg-red {
  color: #fff;
  background-color: rgb(210, 0, 25);
}

.path-active {
  color: rgb(210, 0, 25);
}

.link {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.el-dropdown {
  font-size: 16px;
}
.el-dropdown-menu__item {
  padding: 0;
  font-size: 16px;
}

.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: rgb(210, 0, 25);
  // color: white;
  font-size: 16px;
}

.header {
  position: absolute;
  z-index: 1;
  box-sizing: border-box;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
}

.header-cnt {
  display: flex;
  //   width: 100%;
  height: 38px;
  //   margin-left: 216px;
  padding: 0 48px;
  padding-top: 33px;
  text-align: center;
  justify-content: space-between;

  .left-header {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    .w216 {
      width: 216px;
    }
    .w17 {
      width: 170px;
    }

    .logo {
      margin-right: 10px;

      img {
        width: 36px;
        height: 36px;
      }
    }

    .text {
      line-height: 38px;
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .center-header {
    width: 720px;
    // margin-left: 170px;
    padding: 0;

    ul {
      display: flex;
      line-height: 38px;
      align-items: center;
      justify-content: space-between;
    }
  }

  .right-header {
    flex-shrink: 0;
    display: flex;
    width: 100px;
    height: 38px;
    // margin-left: 215px;
    align-items: center;
    padding-left: 10px;
    // background-color: rgb(210, 0, 25);
    cursor: pointer;
    img{
      width: 102px;
      height: 34px;
    }


    // .icon {
    //   line-height: 14px;
    //   vertical-align: center;

    //   img {
    //     width: 15px;
    //     height: 19px;
    //   }
    // }

    .downloadApp {
      margin-left: 8px;
      font-size: 12px;
      color: white;
      line-height: 38px;
    }
  }
}
</style>
