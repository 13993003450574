<template>
  <transition name="fade-transform" mode="out-in">
    <keep-alive >
      <!-- <router-link to="/qsddHome" v-if="showPage == 1" ></router-link>
      <router-link to="/Home" v-if="showPage == 2" ></router-link> -->
      <router-view/>
      <!-- <router-view  v-if="showPage == 1" name="m"/>
      <router-view  v-if="showPage == 2" name="pc" /> -->
    </keep-alive>
 </transition>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      showPage:1,//1为移动端，2为pc

    };
  },
  created() {
    if (this._isMobile() || document.documentElement.clientWidth < 750) {
      // this.showPage = 1; // 移动端
      this.$router.push({ name: 'm_qsddHome' });
      window.resetRem();
    }else if(!this._isMobile() || document.documentElement.clientWidth >= 750){
      // this.showPage = 2; // pc端
      this.$router.push({ name: 'Home' });
    }
    //  else {
    //   this.showPage = 2; // pc端
    //   this.$router.push({ name: 'Home' });
    // }
    // console.log('this.showPage',this.showPage);
  },
  mounted() {
    // 页面加载完成后重置rem
    // window.resetRem();
    
  },
  beforeDestroy() {
    // 组件销毁前移除事件监听，避免影响其他组件
  
  },
  methods: {
    // 判断是否为移动端
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      // console.log(flag);
      return flag;
    },
  },
};
</script>

<style lang="scss">
// html{
//   font-size: 100px;
// }


html,body {
  height: 100%;
  // overscroll-behavior: none;
  // touch-action: pan-y;
  // min-height: 100vh;
}

/* 版心 */
.w-pc {
  max-width: 1478px;
  // max-width: pxttrem(1478);
  min-width: 1000px;
  // min-width: pxttrem(1000);
  margin: 0 auto;
}
/* fade-transform */
/* .fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all .3s;
}

.fade-transform-enter {
  opacity: .5;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
} */

/* fade */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

/* 设计稿宽度 */
// $design-width:1920;
// @function pxttrem($px){
//   @return $px/$design-width*10+rem;
// }
</style>
<style>
  body{
    /* font-size: 14px; */
  }
</style>
