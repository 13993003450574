

// baseUlrl

// var baseURL = 'http://192.168.31.99/api/mallAdmin' // 开发
// var baseURL = 'https://mall-admin.mihuan.shop/api/backend' // 正式

let baseUrl= "http://192.168.31.99/api/mallAdmin";   //这里是一个默认的url，可以没有
switch (process.env.NODE_ENV) {
    case 'development':
        baseUrl = "http://192.168.31.99/api/mallAdmin"  //开发环境url
        break
    // case 'ceshi':   // 注意这里的名字要和步骤二中设置的环境名字对应起来
    //     baseUrl = "http://localhost:3000/"  //测试环境中的url
    //     break
    case 'production':
        baseUrl = "https://mall-admin.mihuan.shop/api/backend"   //生产环境url
        break
}
 
export default baseUrl;
