import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import "./init.js"

import 'amfe-flexible'
import '@/utils/rem.js'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

import Vant from 'vant';
import 'vant/lib/index.css';
 
Vue.use(Vant);

Vue.config.productionTip = false


//判断是否是移动端
// const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
// //适配方法
// const setRem = () => {
//   // 默认基准值为37.5，对应的设计稿宽度为375px
//   const baseSize = isMobile? 75 : 198; 
//   // 获取屏幕宽度
//   const screenWidth = document.documentElement.clientWidth || document.body.clientWidth;
//   // 设置HTML的根字体大小
//   document.documentElement.style.fontSize = (screenWidth / baseSize) + 'px';
// }

// // 初始化设置
// setRem()
// // 窗口大小改变时重新设置
// window.onresize = function () {
//   setRem()
// }


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')