<!-- header组件 -->
 <template>
   <div class="topBox">
      <!-- <img src="@/assets/imgs/closeBlack.png" class="close" alt=""> -->
       <div class="logoBox">
        <img src="@/assets/mobile/imgs/logo.png" class="logo" alt="">
        <div class="logoText">秘欢</div>
       </div>
      <!-- <div class="title">{{ title }}</div> -->
       <!-- 点击展示右侧弹出框  -->
      <img src="@/assets/mobile/imgs/threeLine.png" class="dot" alt="" @click="openPop">

      <!-- 右侧弹出框   -->
      <van-popup v-model="showPop" position="right" :closeable="true" :style="{ height: '100%', width:'60%' }" get-container="dot"  :safe-area-inset-bottom="true">
        <div class="popBox">
            <!-- :class="$route.path == item.path ? 'path-active' : ''" -->
            <div class="menuBox"  v-for="(item,index) in menuList" :key="index" @click="toMenu(item.title)" >
                <div class="menu">
                    <div>{{ item.title }}</div>
                    <img src="@/assets/mobile/imgs/menuRight.png" alt="">
                </div>
            </div>
            <!-- <div class="menuBox">
                <div class="menu">
                    <div>新闻中心</div>
                    <img src="@/assets/imgs/menuRight.png" alt="">
                </div>
            </div> -->
            <!-- <div class="menuBox">
                <div class="menu">
                    <div>关于我们</div>
                    <img src="@/assets/imgs/menuRight.png" alt="">
                </div>
            </div>
            <div class="menuBox">
                <div class="menu">
                    <div>产品介绍</div>
                    <img src="@/assets/imgs/menuRight.png" alt="">
                </div>
            </div>
            <div class="menuBox">
                <div class="menu">
                    <div>寻找合伙人</div>
                    <img src="@/assets/imgs/menuRight.png" alt="">
                </div>
            </div>
            <div class="menuBox">
                <div class="menu">
                    <div>下载中心</div>
                    <img src="@/assets/imgs/menuRight.png" alt="">
                </div>
            </div> -->
        </div>
      </van-popup>
    </div>
 </template>
 
 <script>

 export default {
    // props:['title'],
    data(){
        return {
            showPop:false,//是否展示右侧弹出框
            isActive:false,
            menuList:[
                {
                    title:'首页',
                    id:0,
                    img:'@/assets/mobile/imgs/menuRight.png',
                    path:'/mobile/qsddHome'

                },
                {
                    title:'新闻中心',
                    id:1,
                    img:'@/assets/mobile/imgs/menuRight.png',
                    path:'/mobile/qsddNews/qsddNews'
                },
                {
                    title:'关于我们',
                    id:2,
                    img:'@/assets/mobile/imgs/menuRight.png',
                    path:'/mobile/qsddAbout'
                },
                {
                    title:'产品介绍',
                    id:3,
                    img:'@/assets/mobile/imgs/menuRight.png',
                    path:'/mobile/qsddProduct'
                },
                {
                    title:'寻找合伙人',
                    id:4,
                    img:'@/assets/mobile/imgs/menuRight.png',
                    path:'/mobile/qsddPartner'
                },
                {
                    title:'下载中心',
                    id:5,
                    img:'@/assets/mobile/imgs/menuRight.png',
                    path:'/mobile/qsddDownload'
                },
        ]
        }
    },
    mounted() {
        // 监听右侧弹出框的关闭事件
        // this.$on('close',()=>{
        //     this.showPop = !this.showPop;
        // })
    },
    methods:{
        // 返回一个特定的 DOM 节点，作为挂载的父节点
        // getContainer() {
        //     return document.querySelector('.dot');
        // },
        // 打开弹窗
        openPop(){
            this.showPop = true;
            // this.showPop = !this.showPop;
            // this.getContainer();   
        },
        // 跳转到对应页面
        toMenu(title){
            // console.log('title',title);
            
            // console.log(this.$router.currentRoute);//当前路由
            
            if(this.$router.currentRoute.name !== title){
                switch(title){
                    case '首页':
                        this.$router.push('/');
                        this.showPop = false;
                    break;
                    case '新闻中心':
                        this.$router.push('/mobile/qsddNews/qsddNews');
                        // this.showPop = !this.showPop;
                        this.showPop = false;
                    break;
                    case '关于我们':
                        this.$router.push('/mobile/qsddAbout');
                        // this.showPop = !this.showPop;
                        this.showPop = false;
                    break;
                    case '产品介绍':
                        this.$router.push('/mobile/qsddProduct');
                        // this.showPop = !this.showPop;
                        this.showPop = false;
                    break;
                    case '寻找合伙人':
                        this.$router.push('/mobile/qsddPartner');
                        // this.showPop = !this.showPop;
                        this.showPop = false;
                    break;
                    case '下载中心':
                        this.$router.push('/mobile/qsddDownload');
                        // this.showPop = !this.showPop;
                        this.showPop = false;
                    break;
                }
            }else{
                return
            }
            
        }
    },
 
 }
 </script>
 
 <style lang="scss" scoped>

::v-deep.van-popup{
    // background-color: rgba($color: #000000, $alpha: .5);
}
.path-active {
  color: rgb(210, 0, 25);
//   background-color: ;
//   color: #fff;
}
.popBox{
    // 36 + 48
    margin: 84px 31px;
    width: 282px;
    height: 296px;
    border-radius: 0px 0px 0px 0px;
    
    box-sizing: border-box;
    .menuBox{
        box-sizing: border-box;
        width: 158px;
        height: 36px;
        border-radius: 0px 0px 0px 0px;
        border-bottom: 1px solid #EDEDED;
        margin-bottom: 16px;
        .menu{
            height: 20px;
            line-height: 20px;
            display: flex;
            font-weight: 500;
            font-size: 14px;
            justify-content: space-between;
            color: #1A1A1A;
            font-weight: 500;
            img{
                width: 10px;
                height: 10px;
                margin: 5px 0;
            }
        }
    }
}


 .topBox{
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
//   width: 375px;
  height: 52px;
//   padding: 0 17px;
  padding: 14px 14px;
  .logoBox{
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    width: 58px;
    height: 24px;
    line-height: 24px;
    border-radius: 0px 0px 0px 0px;
    .logo{
        width: 24px;
        height: 24px;
        // margin-right: 6px;
    }
    .logoText{
        font-size: 16px;
        color: #fff;
        
    }
  }
  img{
    width: 18px;
    height: 18px;
  }
  .title{
    font-weight: 500;
    font-size: 16px;
    color: #1A1A1A;
    line-height: 22px;
  }
  .close{
    
  }
}
 </style>