// rem等比适配配置文件


// 配置基本大小
let baseSize = 192;


// 设置 rem 函数
function setRem() {

    //当前页面宽度相对于1920px屏幕宽的缩放比例，可根据自己需要修改。
    let scale = document.documentElement.clientWidth / 1920;

    //  vue获取当前的路由  看是不是pc端的路由  如果是就不进这个判断  如果不是pc端路由才进  .pathname
    let currentRoute = window.location.hash;
    // console.log('currentRoute',currentRoute);
    let origin = window.location.origin;
    // console.log('origin',origin);
    
    if (document.documentElement.clientWidth < 750 && !currentRoute.includes('mobile')) {
        // 跳转到移动端路由首页
        window.location.href = origin + '/#/mobile/qsddHome';  
        if(currentRoute.includes('mobile')){
            baseSize = 37.5 * (1920 / 375)
            scale = document.documentElement.clientWidth / 375;  
        }   
    }else if(document.documentElement.clientWidth < 750){
        // 跳转到移动端路由首页
        // window.location.href = origin + '/#/mobile/qsddHome';  
       
        if(currentRoute.includes('mobile')){
            baseSize = 37.5 * (1920 / 375)
            scale = document.documentElement.clientWidth / 375;  
        } 
    }
    else{
        // 跳转到pc端路由首页
        window.location.href = origin + '/#/Home'; 
    }
    document.documentElement.style.fontSize = baseSize * scale + 'px'
    
}
setRem(); //初始化

// 适配 - 重置函数
function resetRem(num) {
 if (num) baseSize = Number(num);
 setRem();
}
window.resetRem = resetRem; // 全局可调用(其他方式也可)

// 改变窗口大小时重置 rem
window.onresize = function () { 
    setRem()
};